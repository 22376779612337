
// @ is an alias to /src
import TextButton from "@/components/atomics/TextButton.vue";
import ToggleCheckbox from "@/components/atomics/ToggleCheckbox.vue";
import TwoFactorQR from "@/components/organisms/TwoFactorQR.vue";
import { OnboardingRepository, RepositoryFactory } from "@/lib/https";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "Authentification",
  components: {
    TextButton,
    ToggleCheckbox,
    TwoFactorQR,
  },

  setup() {
    const isShow = ref(false);
    const totpSecret = ref("");
    const qrCode = ref("");
    const isNext = ref(true);

    const { verifyTOTPSecret } =
      RepositoryFactory.getRepository<OnboardingRepository>(
        OnboardingRepository
      );

    const { turnOnTOTPSecret, turnOffTOTPSecret } = twoFactorMethods();

    const onShowTwoFactorField = async () => {
      if (isShow.value) {
        turnOffTOTPSecret();
        isShow.value = false;
        isNext.value = true;
      } else {
        const values = await turnOnTOTPSecret();
        totpSecret.value = values.secret;
        qrCode.value = "data:image/png;base64, " + values.qrCode;

        isShow.value = true;
        isNext.value = false;
      }
    };

    const onSuccessAuthTwoFactor = async () => {
      isNext.value = true;
    };

    return {
      isShow,
      totpSecret,
      qrCode,
      isNext,
      onShowTwoFactorField,
      verifyTOTPSecret,
      onSuccessAuthTwoFactor,
    };
  },
  methods: {
    goNext() {
      this.$router.push("/onboarding/complete");
    },

    goPrev() {
      this.$router.back();
    },
  },
});

const twoFactorMethods = () => {
  const { turnOnTOTPSecret, turnOffTOTPSecret } =
    RepositoryFactory.getRepository<OnboardingRepository>(OnboardingRepository);

  return {
    turnOnTOTPSecret,
    turnOffTOTPSecret,
  };
};
